import React, { forwardRef, useState, useRef } from 'react'

const TaxInfoSheetForm = forwardRef((ref, onChange) => {
    return (
        <div className='formWrapper'>
            <form className='flex flex-col p-4 bg-white' style={{width: "700px", fontSize: "10px"}}>
                <div className='font-medium text-2xl text-start underline'>Tax Payer Information Sheet 2024 (Tax Year 2024)</div>
                <div className='flex flex-row pt-2 items-center'>
                    <label className='font-medium text-start underline text-base'>TAX PAYER INFORMATION:</label>
                    <button className='px-2 py-1'>Returning Client</button>
                    <button className='px-2 ml-2'>New Client</button>
                </div>
                <div className='flex justify-center items-end pt-2'>
                    <div className='flex flex-col w-full pr-5 gap-1'>
                        <div className='flex flex-row'>
                            <label className='font-medium basis-1/2'>YOUR NAME:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>SOCIAL SECURITY #:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>BIRTHDATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>PRIMARY PHONE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>SECONDARY PHONE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>OCCUPATION:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>DRIVER'S LICENSE #:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>ISSUE DATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>EXPIRATION DATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>STATE DRIVER'S LICENSE ISSUED:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>EMAIL ADDRESS:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>SPOUSE'S NAME:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>SOCIAL SECURITY #:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>BIRTHDATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>PRIMARY PHONE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>SECONDARY PHONE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>OCCUPATION:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>DRIVER'S LICENSE #:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>ISSUE DATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>EXPIRATION DATE:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>STATE DRIVER'S LICENSE ISSUED:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                        <div className='flex'>
                            <label className='font-medium basis-1/2'>EMAIL ADDRESS:</label>
                            <div className='border-black border-b-2 p-1 basis-3/4'></div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-evenly pt-2'>
                    <div className='w-full pr-5 flex flex-col'>
                        <label className='font-medium text-start text-base underline'>ADDRESS SECTION</label>
                        <div className='flex justify-between'>
                            <label className=' font-medium basis-1/2'>STREET:</label>
                            <div className='border-b-2 border-black p-1 basis-3/4'></div>
                        </div>
                        <div className='flex justify-between'>
                            <label className=' font-medium basis-1/2'>CITY:</label>
                            <div className='border-b-2 border-black p-1 basis-3/4'></div>
                        </div>
                        <div className='flex justify-between'>
                            <label className=' font-medium basis-1/2'>STATE:</label>
                            <div className='border-b-2 border-black p-1 basis-3/4'></div>
                        </div>
                        <div className='flex justify-between'>
                            <label className=' font-medium basis-1/2'>ZIP CODE:</label>
                            <div className='border-b-2 border-black p-1 basis-3/4'></div>
                        </div>
                    </div>
                    <div className='w-full text-start'>
                        <label className='font-medium text-start underline pb-3 text-base'>STATUS:</label>
                        <div id='test'>
                            <button className='px-2 py-1'>SINGLE</button>
                            <button className='px-2 py-1'>MARRIED FILING JOINT</button>
                            <button className='px-2 py-1'>MARRIED FILING SEPARATE</button>
                            <button className='px-2 py-1'>HEAD OF HOUSEHOLD</button>
                            <button className='px-2 py-1'>SPOUSE DECEASED</button>
                            <button className='px-2 py-1'>DIVORCED</button>
                        </div>

                    </div>

                </div>
                <div className='flex pt-2'>
                    <div className='font-medium text-start'>If SPOUSE DECEASED after 1/1/2024 enter date here:</div>
                    <div className='border-b-2 border-black ml-2 w-1/5'></div>
                </div>
                <div className='flex pt-2'>
                    <div className='font-medium text-start'>If DIVORCED after 1/1/2020 enter date here:</div>
                    <div className='border-b-2 border-black ml-2 w-1/5'></div>
                </div>
                <div className='text-start flex m-auto pt-2'>
                    <div className=' font-medium'>IF HEAD OF HOUSEHOLD NEED 1 OF THE FOLLOWING IN YOUR NAME:</div>
                    <button>PROPERTY TAX RECEIPT</button>
                    <button>MORTGAGE STATEMENT</button>
                    <button>RENTAL AGREEMENT</button>
                    <button>UTILITY BILL</button>
                </div>
                <div className='text-start flex pt-2'>
                    <div className=' font-medium'>IF DIVORCED, COPY OF DIVORCE DECREE IF CHILDREN ARE BEING CLAIMED:</div>
                    <button className='px-2 py-1'>YES</button>
                    <button className='px-2 py-1'>NO</button>
                </div>
                <div className='text-start font-medium text-base pt-2 underline'>DEPENDENT INFORMATION (If more than 4 dependents, add below):</div>
                <div className='flex justify-evenly dependentDiv pt-2 gap-2'>
                    <div className='flex flex-col w-1/4 justify-center'>
                        <label className='font-medium'>NAME:</label>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                    </div>
                    <div className='flex flex-col w-1/4 justify-center'>
                        <label className='font-medium'>SOCIAL SECURITY:</label>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                    </div>
                    <div className='flex flex-col w-1/4 justify-center'>
                        <label className='font-medium'>RELATIONSHIP:</label>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                    </div>
                    <div className='flex flex-col w-1/4 justify-center'>
                        <label className='font-medium'>BIRTHDATE:</label>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                        <div className='border-b-2 border-black pt-5'></div>
                    </div>
                    <div className='flex flex-col w-1/4 daycare' >
                        <label className='font-medium'>DEPENDENT DAYCARE EXPENSE?:</label>
                        <div className='daycareExpenseDiv'>
                            <p className='mobile'>Dependent 1:</p>
                            <button className='px-2'>YES</button>
                            <button className='px-2'>NO</button>
                        </div>
                        <div className='daycareExpenseDiv'>
                            <p className='mobile'>Dependent 2:</p>
                            <button className='px-2'>YES</button>
                            <button className='px-2'>NO</button>
                        </div>
                        <div className='daycareExpenseDiv'>
                            <p className='mobile'>Dependent 3:</p>
                            <button className='px-2'>YES</button>
                            <button className='px-2'>NO</button>
                        </div>
                        <div className='daycareExpenseDiv'>
                            <p className='mobile'>Dependent 4:</p>
                            <button className='px-2'>YES</button>
                            <button className='px-2'>NO</button>
                        </div>
                    </div>
                </div>
                <div className='text-start gap-2'>
                    <div>
                        <label className=' font-medium  '>PROOF OF RESIDENCY OF DEPENDENT(S) (Example: HEALTH FORM, SCHOOL DOCUMENT): </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                    </div>
                    <div>
                        <label className=' font-medium  '>IS DEPENDENT A STUDENT: </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                    </div>
                    <div>
                        <label className=' font-medium  '>IS DEPENDENT A COLLEGE STUDENT: (IF YES, WILL NEED 1098T FORM) </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                    </div>
                    <div>
                        <label className=' font-medium  '>DID YOU BUY OR SELL ANY VIRTUAL CURRENCY IN 2024: </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                    </div>
                    <div>
                        <label className=' font-medium  '>DID YOU HAVE AN IRS ISSUES IDENTITY PROTECTION PIN NUMBER: </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                        <label className=' font-medium  '>IF YES PLEASE PROVIDE:</label>
                        <div className='border-b-2 border-black p-1 text-left'></div>
                    </div>
                    <div>
                        <label className=' font-medium  '>DO YOU HAVE HEALTH INSURANCE THROUGH THE MARKETPLACE(OBAMA CARE)?: </label>
                        <button className='px-2 py-1'>YES</button>
                        <button className='px-2 py-1'>NO</button>
                        <label className=' font-medium  '>IF YES PLEASE PROVIDE FORM 1095A (CAN'T FIND YOUR FORM? CALL MARKETPLACE @ 1-800-318-2596 AND ASK FOR A REPLACEMENT) </label>
                    </div>
                </div>
                <label className='text-start font-medium pt-5 underline text-base pb-2'>REFUND DIRECT DEPOSIT INFORMATION</label>
                <div className='pr-3'>
                    <div className='flex'>
                        <label className='font-medium basis-1/2 text-start'>BANK NAME:</label>
                        <div className='border-b-2 border-black p-1 basis-3/4'></div>
                    </div>
                    <div className='flex'>
                        <label className='font-medium basis-1/2 text-start'>TYPE OF ACCOUNT (CHECKING OR SAVINGS):</label>
                        <div className='border-b-2 border-black p-1 basis-3/4'></div>
                    </div>
                    <div className='flex'>
                        <label className='font-medium basis-1/2 text-start'>ROUTING NUMBER:</label>
                        <div className='border-b-2 border-black p-1 basis-3/4'></div>
                    </div>
                    <div className='flex'>
                        <label className='font-medium basis-1/2 text-start'>ACCOUNT NUMBER:</label>
                        <div className='border-b-2 border-black p-1 basis-3/4'></div>
                    </div>
                </div>
            </form>
            {/* <ReactToPrint
                trigger={() => <button className='bg-green-600 hover:bg-green-400 p-2 rounded-md text-white mt-5'>Print/Download</button>}
                content={() => componentRef.current}
            /> */}


        </div>


    )
})

export default TaxInfoSheetForm
import React from 'react'

const GeneralEngagementForm = () => {
    return (
        <a className="rounded-lg genEngageA" style={{ width: "700px", fontSize: "12px" }}>
            <div className="generalEngagementForm">
                <div className="generalWrapper">
                    <div className="header">
                        <p>TAXESBYMET.TJR</p>
                        <p>PO BOX 587, OAK GROVE, MO 64075</p>
                        <p>816-690-7997 taxesbymetjr@gmail.com</p>
                    </div>

                    <h3>General Engagement Letter for Individual Tax Return Preparation</h3>

                    <p>This letter is to inform you, the taxpayer, of the services we will provide you, and the responsibility you have for preparation of your tax return.</p>

                    <h4>Tax Return Preparation Services</h4>
                    <ul>
                        <li>We will prepare your 2024 Form 1040, U.S. Individual Income Tax Return, and applicable state tax return(s)
                            based on information you provide. Services for preparation of your returns do not include auditing or
                            verification of information provided by you. </li>
                        <li>This engagement does not include any audit or examination of your books or records. In the event
                            your return is audited, you will be responsible for verifying the items reported. </li>
                        <li>Assisting you with your compliance with the Corporate Transparency Act, including beneficial ownership information reporting, is not within the scope of this engagement. </li>
                        <li>The tax return preparation fee does not include bookkeeping. Additional fees apply for these services. </li>
                        <li>Fees charged for tax return preparation do not include audit representation or preparing materials to
                            respond to <br/> correspondence from taxing authorities. </li>
                        <li>Preparation fees do cover limited assistance and consultation during the year.
                        </li>
                        <li>The engagement to prepare your 2024 tax returns terminates upon delivery of your completed returns
                            and original documents to you. Please store your supporting documents and your tax returns in a
                            secure place for at least seven years. You may be assessed a fee if you request a duplicate copy in the
                            future.
                        </li>
                    </ul>


                    <h4>Taxpayer Responsibilities</h4>
                    <ul>
                        <li>You agree to provide us all income and deductible expense information. If you receive additional
                            information after we begin working on your return, you will contact us immediately to ensure your
                            completed tax returns contain all relevant information. </li>
                        <li>You affirm that all expenses or other deduction amounts are accurate and that you have all required
                            supporting written records. In some cases, we will ask to review your documentation.
                        </li>
                        <li>You must be able to provide written records of all items included on your return if audited by either
                            the IRS or state tax authority. We can provide guidance concerning what evidence is acceptable. </li>
                        <li>You must review the returns carefully before signing to make sure the information is correct. </li>
                        <li>Fees must be paid before your tax returns are delivered to you or filed for you. If you terminate this
                            engagement before completion, you agree to pay a fee for work completed. A retainer may be required
                            for preparation of returns. </li>
                        <li className='boldLI'>You consent for the tax preparers to receive, secure and convey electronically submitted
                            information in the preparation of your tax return, Methods include: email, IM, texting
                            e-signature, faxing te company and company employee’s electronic equipment, </li>
                    </ul>

                    <div className="signature">
                        <p>Taxpayer</p>
                        <p>Spouse</p>
                        <p>Date</p>
                    </div>

                    {/* <div className="html2pdf__page-break"></div> */}

                    <p><b>Privacy Policy.</b> The nature of our work requires us to collect certain nonpublic personal information
                        about you from various sources. We collect financial and personal information from applications, worksheets, reporting statements, and other forms, as well as interviews and conversations with our clients
                        and affiliates. We may also review banking and credit card information about our clients in the performance of receipt of payment. Under our policy, all information we obtain about you will be provided by
                        you or obtained with your permission. </p>
                    <br />
                    <p>Our firm has procedures and policies in place to protect your confidential information. We restrict access to your confidential information to those within our firm who need to know in order to provide you
                        with services. We will not disclose your personal information to any third party without your express
                        written permission, except where required by law. We maintain physical, electronic, and procedural
                        safeguards in compliance with federal regulations that protect your personal information from unauthorized access. Please contact us with any questions regarding our privacy policy.</p>
                </div>
            </div>
        </a>

    )
}

export default GeneralEngagementForm
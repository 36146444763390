import React from 'react'



const TaxPrepListForm = () => {
    return (
        <div className='prepListDiv'>
            <a className="rounded-lg text-xs">
                <div className='prepListCont'>
                    <h1>2024 Tax Preparation Checklist</h1>
                    <p><b>Please provide the following documentation: </b></p>
                    <p><span className='questionMark'><b>?</b></span> <b>All Forms W-2 (wages) SSA-1099(social security), 1099-INT (interest), 1099-DIV (dividends),</b></p>
                    <p><b>1099-B (proceeds from broker or barter transactions), 1099-R (pensions and IRA distributions),</b></p>
                    <p><b>Schedules K-1 from partnerships, S corporations, estates and trusts, and other income</b></p>
                    <p><b>reporting statements, including all copies provided from the payer, 1099-K or 1099-G</b></p>
                    <p><b>(Unemployment compensation),1099-MISC, OR 1099-NEC.</b></p>
                    <p><span className='questionMark'><b>?</b></span> <b>Form 1095-A (for health insurance purchased through a public exchange ”“Obamacare” Marketplace).</b></p>
                    <p><b>If you are a new client, provide copies of last year’s tax returns, social security cards and driver's license.</b></p>
                    <p>The completed Individual Income Tax Yes or No Questions.</p>
                    <p>Copy of the closing statement if you bought or sold real estate.</p>
                    <p>Detail of estimated tax payments made, if any.</p>
                    <p>Income and deductions categorized on a separate sheet for business, rental activities and farms.</p>
                    <p>Student loan interest</p>
                    <p>Charitable cash donations for those that do not itemize up to $600 Married, $300 Single</p>
                    <p>For those who itemize their deductions:</p>
                    <p><span className='questionMark'><b>?</b></span> Mortgage Interest Statement Form 1098</p>
                    <p><span className='questionMark'><b>?</b></span> Out of pocket medical expenses</p>
                    <p><span className='questionMark'><b>?</b></span> Charitable contributions cash and non cash</p>
                    <p><span className='questionMark'><b>?</b></span> Personal Property Tax</p>
                    <p><span className='questionMark'><b>?</b></span> Real Estate Tax</p>
                    <p>For virtual currency users, income from sales will need statement from conversion platform.</p>
                    <p className='afterLooking'>After looking at your answers to yes and no questions, If I need some additional information I will call, or
                        email. We do leave messages so please make sure your voicemail is available and check it during the
                        time we have your information.</p>
                    <p className='thankYou'>Thank you,</p>
                    <p>TaxesByME.TJR</p>
                </div>
            </a>
        </div>
    )
}

export default TaxPrepListForm
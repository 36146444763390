import React from 'react'

const Footer = () => {
    return (
        <footer className="m-5 printPageButton">
            <div className="text-center">
                <h1 className='text-base font-bold sm:text-sm'>© 2024 TAXES BY ME.TJR&trade; All Rights Reserved</h1>
            </div>
        </footer>
    )
}
export default Footer